@charset "utf-8";

// Solarized skin
// ==============
// Created by Sander Voerman <mailto:sander@savoerman.nl> using the Solarized
// color scheme by Ethan Schoonover <https://ethanschoonover.com/solarized>.

// This style sheet implements four options for the minima.skin setting:
// "gruvbox-light" for light mode, "gruvbox-dark" for dark mode, and
// "gruvbox" for light or dark mode depending on user preference.
// "gruvbox-toggle" for light or dark mode depending on user preference, with a toggle to switch between light and dark.
$gb-hard-constrast: false !default;
$gb-soft-constrast: false !default;
$gb-is-auto: true !default;
$gb-is-dark: false !default;
$gb-has-toggle: false !default;



// ----------------------------------------------------------------------------
// Dark Mode
// ----------------------------------------------------------------------------

// Dark Background
$gb-dm-bg0: #282828;
$gb-dm-bg0-hard: #1d2021;
$gb-dm-bg0-soft: #32302f;
$gb-dm-bg1: #3c3836;
$gb-dm-bg2: #504945;
$gb-dm-bg3: #665c54;
$gb-dm-bg4: #7c6f64;

// Dark Foreground
$gb-dm-fg0: #fbf1c7;
$gb-dm-fg1: #ebdbb2;
$gb-dm-fg2: #d5c4a1;
$gb-dm-fg3: #bdae93;
$gb-dm-fg4: #a89984;

// Dark Colors
$gb-dm-soft-red: #cc241d;
$gb-dm-soft-green: #98971a;
$gb-dm-soft-yellow: #d79921;
$gb-dm-soft-blue: #458588;
$gb-dm-soft-purple: #b16286;
$gb-dm-soft-aqua: #689d6a;
$gb-dm-soft-orange: #d65d0e;
$gb-dm-soft-gray: #928374;

$gb-dm-hard-red: #fb4934;
$gb-dm-hard-green: #b8bb26;
$gb-dm-hard-yellow: #fabd2f;
$gb-dm-hard-blue: #83a598;
$gb-dm-hard-purple: #d3869b;
$gb-dm-hard-aqua: #8ec07c;
$gb-dm-hard-orange: #f38019;
$gb-dm-hard-gray: #a89984;

// ----------------------------------------------------------------------------
// Light Mode
// ----------------------------------------------------------------------------

// Light Background
$gb-lm-bg0: #fbf1c7;
$gb-lm-bg0-hard: #f9f5d7;
$gb-lm-bg0-soft: #f2e5bc;
$gb-lm-bg1: #ebdbb2;
$gb-lm-bg2: #d5c4a1;
$gb-lm-bg3: #bdae93;
$gb-lm-bg4: #a89984;

// Light Foreground
$gb-lm-fg0: #282828;
$gb-lm-fg1: #3c3836;
$gb-lm-fg2: #504945;
$gb-lm-fg3: #665c54;
$gb-lm-fg4: #7c6f64;

// Light Colors
$gb-lm-soft-red: #cc241d;
$gb-lm-soft-green: #98971a;
$gb-lm-soft-yellow: #d79921;
$gb-lm-soft-blue: #458588;
$gb-lm-soft-purple: #b16286;
$gb-lm-soft-aqua: #689d6a;
$gb-lm-soft-orange: #d65d0e;
$gb-lm-soft-gray: #928374;

$gb-lm-hard-red: #9d0006;
$gb-lm-hard-green: #79740e;
$gb-lm-hard-yellow: #b57614;
$gb-lm-hard-blue: #076678;
$gb-lm-hard-purple: #8f3f71;
$gb-lm-hard-aqua: #427b58;
$gb-lm-hard-orange: #af3a03;
$gb-lm-hard-gray: #7c6f64;

@if $gb-hard-constrast {
  $gb-dm-bg0: $gb-dm-bg0-hard;
  $gb-lm-bg0: $gb-lm-bg0-hard;
}

@if $gb-soft-constrast {
  $gb-dm-bg0: $gb-dm-bg0-soft;
  $gb-lm-bg0: $gb-lm-bg0-soft;
}






@mixin light-colors {
    --gb-bg0: #{$gb-lm-bg0};
    --gb-bg0-hard: #{$gb-lm-bg0-hard};
    --gb-bg0-soft: #{$gb-lm-bg0-soft};
    --gb-bg1: #{$gb-lm-bg1};
    --gb-bg2: #{$gb-lm-bg2};
    --gb-bg3: #{$gb-lm-bg3};
    --gb-bg4: #{$gb-lm-bg4};
    --gb-fg0: #{$gb-lm-fg0};
    --gb-fg1: #{$gb-lm-fg1};
    --gb-fg2: #{$gb-lm-fg2};
    --gb-fg3: #{$gb-lm-fg3};
    --gb-fg4: #{$gb-lm-fg4};
    --gb-soft-red: #{$gb-lm-soft-red};
    --gb-soft-green: #{$gb-lm-soft-green};
    --gb-soft-yellow: #{$gb-lm-soft-yellow};
    --gb-soft-blue: #{$gb-lm-soft-blue};
    --gb-soft-purple: #{$gb-lm-soft-purple};
    --gb-soft-aqua: #{$gb-lm-soft-aqua};
    --gb-soft-orange: #{$gb-lm-soft-orange};
    --gb-soft-gray: #{$gb-lm-soft-gray};
    --gb-hard-red: #{$gb-lm-hard-red};
    --gb-hard-green: #{$gb-lm-hard-green};
    --gb-hard-yellow: #{$gb-lm-hard-yellow};
    --gb-hard-blue: #{$gb-lm-hard-blue};
    --gb-hard-purple: #{$gb-lm-hard-purple};
    --gb-hard-aqua: #{$gb-lm-hard-aqua};
    --gb-hard-orange: #{$gb-lm-hard-orange};
    --gb-hard-gray: #{$gb-lm-hard-gray};
}

@mixin dark-colors {
    --gb-bg0: #{$gb-dm-bg0};
    --gb-bg0-hard: #{$gb-dm-bg0-hard};
    --gb-bg0-soft: #{$gb-dm-bg0-soft};
    --gb-bg1: #{$gb-dm-bg1};
    --gb-bg2: #{$gb-dm-bg2};
    --gb-bg3: #{$gb-dm-bg3};
    --gb-bg4: #{$gb-dm-bg4};
    --gb-fg0: #{$gb-dm-fg0};
    --gb-fg1: #{$gb-dm-fg1};
    --gb-fg2: #{$gb-dm-fg2};
    --gb-fg3: #{$gb-dm-fg3};
    --gb-fg4: #{$gb-dm-fg4};
    --gb-soft-red: #{$gb-dm-soft-red};
    --gb-soft-green: #{$gb-dm-soft-green};
    --gb-soft-yellow: #{$gb-dm-soft-yellow};
    --gb-soft-blue: #{$gb-dm-soft-blue};
    --gb-soft-purple: #{$gb-dm-soft-purple};
    --gb-soft-aqua: #{$gb-dm-soft-aqua};
    --gb-soft-orange: #{$gb-dm-soft-orange};
    --gb-soft-gray: #{$gb-dm-soft-gray};
    --gb-hard-red: #{$gb-dm-hard-red};
    --gb-hard-green: #{$gb-dm-hard-green};
    --gb-hard-yellow: #{$gb-dm-hard-yellow};
    --gb-hard-blue: #{$gb-dm-hard-blue};
    --gb-hard-purple: #{$gb-dm-hard-purple};
    --gb-hard-aqua: #{$gb-dm-hard-aqua};
    --gb-hard-orange: #{$gb-dm-hard-orange};
    --gb-hard-gray: #{$gb-dm-hard-gray};
}


@if $gb-is-auto {
  :root {
    @include light-colors;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      @include dark-colors;
    }
  }
  :root .dark {
      @include dark-colors;
    }
  :root .light {
      @include dark-colors;
    }
} @else {
  @if $gb-is-dark {
  :root {
    @include dark-colors;
  }
  } @else {
    :root {
      @include light-colors;
  }
  }
}



$gb-bg0: var(--gb-bg0);
$gb-bg0-hard: var(--gb-bg0-hard);
$gb-bg0-soft: var(--gb-bg0-soft);
$gb-bg1: var(--gb-bg1);
$gb-bg2: var(--gb-bg2);
$gb-bg3: var(--gb-bg3);
$gb-bg4: var(--gb-bg4);
$gb-fg0: var(--gb-fg0);
$gb-fg1: var(--gb-fg1);
$gb-fg2: var(--gb-fg2);
$gb-fg3: var(--gb-fg3);
$gb-fg4: var(--gb-fg4);
$gb-soft-red: var(--gb-soft-red);
$gb-soft-green: var(--gb-soft-green);
$gb-soft-yellow: var(--gb-soft-yellow);
$gb-soft-blue: var(--gb-soft-blue);
$gb-soft-purple: var(--gb-soft-purple);
$gb-soft-aqua: var(--gb-soft-aqua);
$gb-soft-orange: var(--gb-soft-orange);
$gb-soft-gray: var(--gb-soft-gray);
$gb-hard-red: var(--gb-hard-red);
$gb-hard-green: var(--gb-hard-green);
$gb-hard-yellow: var(--gb-hard-yellow);
$gb-hard-blue: var(--gb-hard-blue);
$gb-hard-purple: var(--gb-hard-purple);
$gb-hard-aqua: var(--gb-hard-aqua);
$gb-hard-orange: var(--gb-hard-orange);
$gb-hard-gray: var(--gb-hard-gray);



// Minima color variables
// ----------------------

$brand-color:           $gb-fg2 !default;
$brand-color-light:     $gb-bg3 !default;
$brand-color-dark:      $gb-fg1 !default;

$site-title-color:      $gb-fg1 !default;

$text-color:            $gb-fg1 !default;
$background-color:      $gb-bg0 !default;
$code-background-color: $gb-bg1 !default;

$link-base-color:       $gb-hard-blue !default; // hard-yellow also works well
$link-visited-color:    $link-base-color !default;
$link-hover-color:      $gb-fg2 !default;

$border-color-01:       $gb-fg4 !default;
$border-color-02:       $gb-fg3 !default;
$border-color-03:       $gb-fg2 !default;

$table-text-color:      $gb-fg1 !default;
$table-zebra-color:     $gb-bg1 !default;
$table-header-bg-color: $gb-bg2 !default;
$table-header-border:   $gb-fg2 !default;
$table-border-color:    $gb-fg2 !default;


// Syntax highlighting styles
// --------------------------

.highlight {
  .c     { color: $gb-fg2; font-style: italic } // Comment
  .err   { color: $gb-hard-red } // Error
  .k     { color: $gb-fg0; font-weight: bold } // Keyword
  .o     { color: $gb-fg0; font-weight: bold } // Operator
  .cm    { color: $gb-fg2; font-style: italic } // Comment.Multiline
  .cp    { color: $gb-fg2; font-weight: bold } // Comment.Preproc
  .c1    { color: $gb-fg2; font-style: italic } // Comment.Single
  .cs    { color: $gb-fg2; font-weight: bold; font-style: italic } // Comment.Special
  .gd    { color: $gb-soft-red } // Generic.Deleted
  .gd .x { color: $gb-hard-red } // Generic.Deleted.Specific
  .ge    { color: $gb-fg1; font-style: italic } // Generic.Emph
  .gr    { color: $gb-hard-red } // Generic.Error
  .gh    { color: $gb-fg2 } // Generic.Heading
  .gi    { color: $gb-hard-green } // Generic.Inserted
  .gi .x { color: $gb-soft-green } // Generic.Inserted.Specific
  .go    { color: $gb-fg1 } // Generic.Output
  .gp    { color: $gb-fg1 } // Generic.Prompt
  .gs    { color: $gb-fg0; font-weight: bold } // Generic.Strong
  .gu    { color: $gb-fg2 } // Generic.Subheading
  .gt    { color: $gb-soft-red } // Generic.Traceback
  .kc    { color: $gb-soft-orange; font-weight: bold } // Keyword.Constant
  .kd    { color: $gb-soft-orange; font-weight: bold } // Keyword.Declaration
  .kp    { color: $gb-soft-orange; font-weight: bold } // Keyword.Pseudo
  .kr    { color: $gb-soft-orange; font-weight: bold } // Keyword.Reserved
  .kt    { color: $gb-hard-purple; font-weight: bold } // Keyword.Type
  .m     { color: $gb-hard-aqua } // Literal.Number
  .s     { color: $gb-soft-purple } // Literal.String
  .na    { color: $gb-hard-aqua } // Name.Attribute
  .nb    { color: $gb-hard-blue } // Name.Builtin
  .nc    { color: $gb-soft-blue; font-weight: bold } // Name.Class
  .no    { color: $gb-hard-aqua } // Name.Constant
  .ni    { color: $gb-soft-blue } // Name.Entity
  .ne    { color: $gb-hard-purple; font-weight: bold } // Name.Exception
  .nf    { color: $gb-hard-blue; font-weight: bold } // Name.Function
  .nn    { color: $gb-fg1 } // Name.Namespace
  .nt    { color: $gb-hard-blue } // Name.Tag
  .nv    { color: $gb-hard-aqua } // Name.Variable
  .ow    { color: $gb-fg0; font-weight: bold } // Operator.Word
  .w     { color: $gb-fg2 } // Text.Whitespace
  .mf    { color: $gb-soft-aqua } // Literal.Number.Float
  .mh    { color: $gb-soft-aqua } // Literal.Number.Hex
  .mi    { color: $gb-soft-aqua } // Literal.Number.Integer
  .mo    { color: $gb-soft-aqua } // Literal.Number.Oct
  .sb    { color: $gb-soft-purple } // Literal.String.Backtick
  .sc    { color: $gb-soft-purple } // Literal.String.Char
  .sd    { color: $gb-soft-purple } // Literal.String.Doc
  .s2    { color: $gb-soft-purple } // Literal.String.Double
  .se    { color: $gb-soft-purple } // Literal.String.Escape
  .sh    { color: $gb-soft-purple } // Literal.String.Heredoc
  .si    { color: $gb-soft-purple } // Literal.String.Interpol
  .sx    { color: $gb-soft-purple } // Literal.String.Other
  .sr    { color: $gb-hard-green } // Literal.String.Regex
  .s1    { color: $gb-soft-purple } // Literal.String.Single
  .ss    { color: $gb-soft-purple } // Literal.String.Symbol
  .bp    { color: $gb-fg2 } // Name.Builtin.Pseudo
  .vc    { color: $gb-hard-aqua } // Name.Variable.Class
  .vg    { color: $gb-hard-aqua } // Name.Variable.Global
  .vi    { color: $gb-hard-aqua } // Name.Variable.Instance
  .il    { color: $gb-soft-aqua } // Literal.Number.Integer.Long
}


::selection {
  background-color: $gb-fg1 ! important;
  color: $gb-bg1 ! important;
}

@if $gb-has-toggle {

.dark body {
    @include dark-colors;
}
.light body {
    @include light-colors;
}



#theme-toggle {
  visibility: hidden;
  display: none;
}

#theme-toggle + label {
  display: inline-flex;
  cursor: pointer;
  height: 26px;
  width: 52px;
  border-radius: 1.2em;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: 5px;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 1px rgba(1, 1, 1, 0.1) inset;
  padding: 2px;
}

.theme-icon {
  transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  transform: rotate(0);
}
.theme-icon:hover {
  transform: rotate(360deg);
}
.theme-icon.moon {
  color: var(--gb-soft-yellow);
}

.theme-icon.sun {
  color: var(--gb-soft-yellow);
}


#theme-toggle + label .ball {
  background-color: var(--gb-fg4);
  position: absolute;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  transition: all 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

#theme-toggle + label .theme-icon {
  padding: 1px;
}

.dark body {
    #theme-toggle ~ label {
        .ball {
            transform: translatex(100%);
        }
    }
}

#theme-toggle:not(:checked) ~ label {
  .ball {
    transform: translatex(0);
  }
}

#theme-toggle:checked ~ label {
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5) inset;
  .ball {
    transform: translatex(100%);
  }
}
}
